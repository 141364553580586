@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');

html,
body {
  width: 100%;
  font-family: 'Montserrat', sans-serif;
}

body {
  overflow-x: hidden;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 2px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #003399;
  border-radius: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #003399;
}

nav.fixed.navbar.navbar-expand-xl.navbar-light {
  background: #ffffff !important;
  box-shadow: 0 4px 10px rgb(0 0 0 / 15%);
  border: none;
}

.nav-item.dropdown a {
  display: flex;
  align-items: center;
  font-size: 13px;
}

.flex-grow-1.navbar-nav {
  justify-content: flex-end;
  align-items: center;
}

.flex-grow-1.navbar-nav a {
  display: flex;
  align-items: center;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
}

.nav-item.dropdown:hover .dropdown-menu {
  display: block;
}

a.nav-link {
  font-style: normal;
  font-size: 13px;
  line-height: 20px;
  color: #000000;
}

.nav-link:focus,
.nav-link:hover {
  color: #f61d28;
}

.navbar-nav .nav-link.active,
.navbar-nav .show>.nav-link {
  color: rgb(246 29 40);
}

img.nav_logo {
  width: 80px;
}

.footer .footer-box .footer-heading h4 {
  position: relative;
  font-size: 18px;
}

.footer .footer-box .footer-heading h4:before {
  content: "";
  width: 20px;
  height: 20px;
  background: #27346a;
  display: inline-flex;
  position: absolute;
  left: 95px;
  bottom: -8px;
}

.footer .footer-box .footer-heading h4:before {
  background-color: #FF000F;
}

ul li {
  margin: 0;
  position: relative;
}

ul.custom {
  list-style: none;
  padding: 0;
}

.section-padding {
  padding: 50px 0px 20px;
}

.form-group {
  margin-bottom: 20px;
}

.form-control-custom {
  background: #fff none repeat scroll 0 0;
  border-radius: 0;
  border: 1px solid #f1ece2;
  box-shadow: none;
  color: #888;
  font-size: 14px;
  height: 51px;
  line-height: 30px;
  padding: 10px 20px;
  width: 100%;
}

.form-control-custom:hover {
  box-shadow: none;
  outline: none;
}

.form-control-custom:focus {
  box-shadow: none;
  border-color: #0070ba;
  outline: none;
}

.btn-first {
  display: inline-flex;
  padding: 10px 40px;
  position: relative;
  transition: .6s;
  background: #003399;
  color: white;
  border: 1px solid transparent;
  font-size: 12px;
  overflow: hidden;
  z-index: 1;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
}

.footer .btn-submit-fill {
  color: #fff;
  border: 1px solid #ff0418;
  background-color: #ff0418;
  transition: all .5s;
  width: 100%;
}

.btn-submit-fill:focus,
.btn-submit-fill:hover {
  color: #fff;
  border-color: #003399;
  background-color: #003399;
}

.text-custom-white {
  color: #fff;
}

.footer .footer-box .newsletter button {
  color: #fff;
}

.bg-black {
  background-color: #003399 !important;
}

.footer .footer-box .footer-heading {
  position: relative;
  margin-bottom: 25px;
}

.footer .footer-box .footer-heading h4:after {
  content: "";
  width: 80px;
  height: 3px;
  background-color: #fff;
  display: block;
  margin-top: 20px;
}

.footer .footer-box .links li {
  font-size: 14px;
  margin-bottom: 10px;
  padding-left: 25px;
}

.footer .footer-box .links li:before {
  content: "";
  position: absolute;
  left: 0;
  background-color: #fff;
  width: 15px;
  height: 3px;
  top: 11px;
}

.footer .footer-box .links li:last-child,
.footer .footer-box .popular_post li:last-child {
  margin-bottom: 0;
}

.footer .footer-box .newsletter .form-group,
.footer .footer-box .popular_post li {
  margin-bottom: 15px;
}

.footer .footer-box .social-media ul {
  margin-top: 15px;
  display: flex;
}

.footer .footer-box .social-media ul li a {
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  margin: 0 8px 0 0;
  border: 1px solid #fff;
}

.footer .footer-box .social-media ul li a:hover {
  background-color: #0070ba;
  color: #fff;
  -webkit-transform: rotate(1turn);
  transform: rotate(1turn);
}

.footer .footer-box .twitter-feed {
  display: flex;
  height: 170px;
  overflow: hidden;
}

.footer .footer-box .twitter-feed li .tweet {
  line-height: 24px;
  font-size: 12px;
  color: #fff;
  float: left;
  padding: 15px;
  font-weight: 500;
  margin-bottom: 10px;
  position: relative;
}

.footer .footer-box .twitter-feed li .tweet:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: hsla(0, 0%, 100%, .071);
}

.footer .footer-box .twitter-feed li .tweet:after {
  color: #fff;
  font-family: "Font Awesome 5 Brands";
  content: "\f099";
  font-weight: 900;
  position: absolute;
  bottom: 10px;
  right: 12px;
  opacity: 1;
  font-size: 24px;
}

.footer .footer-box .twitter-feed li .tweet a {
  color: #fff;
}

.footer .footer-box .twitter-feed li .timePosted a {
  color: #ccc;
  position: relative;
  float: left;
  margin-bottom: 20px;
  padding-left: 20px;
}

.footer .footer-box .twitter-feed li .timePosted a:before {
  content: "\f017";
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
  position: absolute;
  top: 0;
  left: 0;
  color: #fff;
  opacity: .7;
  font-size: 14px;
}

.footer .footer-box .twitter-feed li .timePosted a:after {
  margin-left: 7px;
  top: -10px;
  left: 22px;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border: solid transparent;
  border-top: solid hsla(0, 0%, 100%, .071);
}

.footer .footer-box .popular_post li .post .post-wrapper {
  width: 100%;
  display: flex;
}

.footer .footer-box .popular_post li .post .post-wrapper .popular_post_img {
  position: relative;
  overflow: hidden;
  flex: 0 0 80px;
  max-width: 80px;
  height: 80px;
}

.footer .footer-box .popular_post li .post .post-wrapper .popular_post_img img {
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: cover;
}

.footer .footer-box .popular_post li .post .post-wrapper .popular_post_title {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(100% - 80px);
  max-width: calc(100% - 80px);
  padding: 2px 0 2px 15px;
  width: 100%;
}

.footer .footer-box .popular_post li .post .post-wrapper .popular_post_title h6 {
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #FFFFFF;
}

p.text-white.no-margin {
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  color: #FFFFFF;
}

.footer .footer-box .popular_post li .post .post-wrapper .popular_post_title h6 a {
  font-weight: 500;
}

.footer .footer-box .newsletter button:hover {
  background-color: #fff;
  color: #0070ba;
}

.copyright .payment-logo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.copyright p {
  text-align: right;
}

@media (max-width:992px) {
  .mb-md-20 {
    margin-bottom: 20px;
  }

  .copyright .payment-logo {
    justify-content: center;
  }

  .copyright p {
    text-align: center;
  }
}

@media (max-width:576px) {
  .copyright .payment-logo {
    display: block;
    text-align: center;
  }
}

@media all {
  .top_menu {
    background-color: #ed2a30;
    width: 100%;
  }

  .top_menu ul {
    padding: 6px 0px;
    text-align: right;
    padding-left: 0px;
    margin-bottom: 4px;
    list-style-type: none;
  }

  span.info-header-icons {
    padding-right: 5px;
  }

  .top_menu ul li {
    display: inline-block;
    letter-spacing: 0.8px;
    padding: 0 0 0 15px;

    font-weight: 400;
    font-size: 11px;
    color: #FFFFFF;
  }

  span.info-header-icons img {
    width: 12px;
  }

  .top_menu ul li a {
    color: #fff;
    font-size: 11px;
    letter-spacing: 0.8px;
  }

  @media (max-width:991px) {
    .top_menu ul li {
      padding: 0px 0 0 26px;
    }
  }

  @media (max-width:768px) {
    .top_menu ul {
      text-align: center;
    }

    .info-header-icons {
      display: inline-block;
      width: 19px;
    }

    .info-header-icons img {
      display: inline-block;
      width: 19px;
    }

    .info-header-text {
      display: none;
    }

    .top_menu ul li {
      letter-spacing: 0px;
      padding: 0px;
    }

    .top_menu ul li a {
      font-size: 14px;
      letter-spacing: 0px;
    }
  }

  @media (max-width:480px) {
    .top_menu ul li a {
      font-size: 12px;
    }
  }
}

footer a:hover {
  color: #ffffff;
}

footer.bg-black.section-padding.footer a {
  text-decoration: none;
}

span.text-custom-white.fs-14.mr-3 {
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  color: #FFFFFF;
  margin-right: 10px;
}

.copyright {
  padding-top: 20px;
  padding-bottom: 20px;
}

section.reachus h3 {
  font-weight: 600;
  font-size: 28px;
  line-height: 20px;
  color: #333333;
  margin-bottom: 30px;
}

section.reachus {
  padding-top: 50px;
  padding-bottom: 50px;
}

section.reachus p {
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #343A40;
}

section.reachus h2 {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #343A40;
}

img.Reachusimg {
  width: 65%;
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-top: 50px;
}

.testimonials_all.container-fluid {
  background: linear-gradient(180deg, rgba(0, 51, 153, 0.06) 0%, rgba(237, 28, 36, 0.06) 100%);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
}

.testimonials_all.container-fluid {
  background: linear-gradient(180deg, rgba(0, 51, 153, 0.06) 0%, rgba(237, 28, 36, 0.06) 100%);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  padding-top: 45px;
  padding-bottom: 45px;
}

.reachus_contact .fa {
  font-size: 20px;
  color: #003399;
  font-weight: bold;
}

.reachus_contact {
  padding: 15px 20px 15px 20px;
  background: rgba(13, 47, 146, 0.07);
  display: flex;
  align-items: baseline;
}

.reachus_contact span {
  letter-spacing: 1px;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: #003399;
  margin-left: 20px;
}

.partners_all.container-fluid {
  background: linear-gradient(180deg, rgba(0, 51, 153, 0.06) 0%, rgba(237, 28, 36, 0.06) 100%);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  padding-top: 45px;
  padding-bottom: 70px;
}

.owl-carousel.partners_img.owl-loaded.owl-drag img {
  width: 100%;
  height: 140px;
  object-fit: contain;
  padding: 15px 15px;
  background: white;
}

button.owl-prev span {
  font-size: 50px;
  color: #424242;
}

button.owl-prev {
  position: absolute;
  left: -3%;
  top: 15%;
}

button.owl-next span {
  font-size: 50px;
  color: #424242;
}

button.owl-next {
  position: absolute;
  right: -3%;
  top: 15%;
}

h3.text-custom-black.fw-700.our_partners {
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  font-family: 'Montserrat', sans-serif;
  line-height: 42px;
  color: #333333;
  margin-bottom: 25px;
}

.rating i {
  font-size: 13px;
  color: #FF9747;
  transition: 0.3s;
}

.testimonial-block {
  position: relative;
  margin-bottom: 30px;
}

.testimonial-block .inner-box {
  position: relative;
  padding: 70px 15px 15px;
}

.testimonial-block .content-box {
  position: relative;
  display: block;
  background-color: #ffffff;
  padding: 30px 40px 20px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.testimonial-block .content-box .thumb {
  position: absolute;
  right: 40px;
  top: -70px;
  height: 140px;
  width: 140px;
  -webkit-box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
}

.testimonial-block .content-box .thumb img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  background: white;
  padding: 5px;
}

.testimonial-block .content-box .rating {
  font-size: 20px;
  color: #f89321;
  margin-bottom: 15px;
}

.testimonial-block .content-box .rating i {
  margin-right: 5px;
}

.testimonial-block .content-box .text {
  position: relative;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #343434;
  margin-bottom: 10px;
}

.testimonial-block .info-box {
  position: relative;
  min-height: 70px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.testimonial-block .info-box .name {
  margin-bottom: 5px;
  font-size: 22px;
}

.testimonial-block .info-box .designation {
  position: relative;
  text-transform: uppercase;
  display: block;
  font-size: 10px;
  color: #808287;
  font-weight: 700;
  letter-spacing: 0.1em;
}

@media only screen and (max-width: 599px) {
  .testimonial-block .content-box {
    padding: 55px 30px 30px;
  }

  .testimonial-block .content-box .thumb {
    height: 100px;
    width: 120px;
  }

  .testimonial-block .info-box {
    padding-left: 0;
  }

  .testimonial-block .info-box .icon-quote {
    position: relative;
    display: inline-block;
    margin-bottom: 10px;
  }

  .testimonial-block .content-box:before {
    width: 200px;
    background-position: right top;
  }

  .testimonial-block .content-box .text {
    font-size: 16px;
    line-height: 24px;
  }
}

.testimonials_all.container-fluid button.owl-prev {
  position: absolute;
  left: -3%;
  top: 42%;
}

.testimonials_all.container-fluid button.owl-next {
  position: absolute;
  right: -3%;
  top: 42%;
}

.offers img {
  width: 100%;
}

.offers {
  padding-top: 60px;
  padding-bottom: 60px;
}

.container.knowmore {
  margin-top: 120px;
  position: relative;
  background: #FEF6F6;
  border-radius: 20px;
  margin-bottom: 80px;
  padding: 40px;
}

.container.knowmore h3 {
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 1.4;
  letter-spacing: -0.5px;
  color: #333846;
}

button.knowmore_button {
  margin-top: 15px;
  color: #fff;
  border: none;
  border-color: #27346a;
  font-size: 14px;
  padding: 13px 20px;
  background: #003399;
  box-shadow: 0px 8px 8px rgba(13, 47, 146, 0.2);
  border-radius: 8px;
}

.container.knowmore span {
  font-weight: 600;
}

.rbt-feature.feature-style-1.align-items-center {
  display: flex;
}

h6.feature-title {
  margin-bottom: 0px;
  margin-left: 15px;
  font-weight: 500;
  font-size: 14px;
  color: #333846;
}

.rbt-feature.feature-style-1.align-items-center {
  margin-top: 20px;
}

img.knowmoreimg {
  width: 300px;
  position: absolute;
  top: -95px;
  left: -30px;
}

.banner_all_one {
  position: relative;
}

.container.banner_sliders h3 {
  font-weight: 600;
  font-size: 30px;
  line-height: 40px;
  color: #FFFFFF;
  margin-bottom: 40px;
  text-align: center;
}

.insuranc p {
  font-weight: 500;
  line-height: 1.3;
  font-size: 14px;
  text-align: center;
  height: 25px;
  color: #333846;
  margin-bottom: 0px;
}

.insuranc img {
  margin-bottom: 20px;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
}

.banner_all_one.mb-5 a {
  text-decoration: none;
}

/* .insuranc {
  background: #FFFFFF;
  border: 0.5px solid #FFFFFF;
  box-shadow: 0px 0px 5px 2px rgba(0, 51, 153, 0.25);
  border-radius: 5px;
  padding: 25px 10px;
} */

.insuranc {
  background: #FFFFFF;
  border: 0.5px solid #FFFFFF;
  box-shadow: 0px 0px 5px 2px rgba(0, 51, 153, 0.25);
  border-radius: 5px;
  padding: 10px 5px 15px 5px;
}

.container.banner_sliders {
  left: 0;
  width: 100%;
  top: 24%;
  position: absolute;
  right: 0;
  z-index: 1;
}

.owl-carousel .owl-item img {
  display: block;
  width: 100%;
}

table.quote_table.table.table-striped.table-bordered.table-hover thead {
  background: #003399;
  border-bottom: 1px solid #000000;
  font-weight: 700;
  font-size: 14px;
  line-height: 25px;
  color: #FFFFFF;
  text-align: center;
}

.page-header {
  position: relative;
  display: block;
  padding: 20px 0 15px;
  background-color: white;
  z-index: 1;
}

.page-header-bg {
  background-image: linear-gradient(rgb(0 0 0/60%), rgb(0 0 0/60%)), url(../src/Image/Banner/image\ 202.png);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: initial;
  z-index: -1;
}

.page-header-bg1 {
  background-image: linear-gradient(rgb(0 0 0/60%), rgb(0 0 0/60%)), url(../src/Image/cancel.png);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: initial;
  z-index: -1;
}

.innerbanner h4.text-custom-white.no-margin {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 15px;
  color: #FFFFFF;
}

.innerbanner h4:before {
  content: "";
  width: 20px;
  height: 20px;
  background: #0D2F92;
  display: inline-flex;
  position: absolute;
  left: 140px;
  bottom: 32px;
}

.innerbanner h4:after {
  margin-bottom: 20px;
  content: "";
  width: 120px;
  height: 3px;
  background-color: #ff000f;
  display: block;
  margin-top: 20px;
}

.page-header__inner {
  position: relative;
}

h6.para_absdasa {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #FFFFFF;
}

.container-fluid.text_avbcds {
  background: linear-gradient(180deg, rgba(0, 51, 153, 0.06) 0%, rgba(237, 28, 36, 0.06) 100%);
  padding: 0;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
}

.voice_abcd {
  padding-top: 15px;
  padding-bottom: 15px;
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  padding-left: 35px;
  align-items: center;
  padding-right: 35px;
}

.insurance_details p {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 30px;
  text-align: justify;
  text-transform: capitalize;
  color: #000000;
}

.insurance_details.pt-5.pb-5 h3 {

  font-style: normal;
  font-weight: 600;
  font-size: 23px;
  line-height: 35px;
  text-transform: capitalize;
  color: #000000;
}

ul.list_abcds li {
  list-style: auto;
}

ul.list_abcds li {
  font-weight: 500;
  font-size: 15px;
  line-height: 35px;
  text-align: justify;
  text-transform: capitalize;
  color: #000000;
}

ul.list_abcds {
  padding-left: 20px;
}

h4.find_abcd {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  margin-top: 20px;
  text-transform: capitalize;
  color: #000000;
}

p.find_abcd {
  font-weight: 400;
  font-size: 13px;
  line-height: 25px;
  text-align: justify;
  text-transform: capitalize;
  color: #000000;
}

.insurance_details.pt-5.pb-5 .accordion-body {
  font-weight: 400;
  font-size: 14px;
  line-height: 27px;
  text-align: justify;
  color: #000000;
}

button.accordion-button {
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #000000;
  padding-left: 0px;
}

.accordion-button:focus {
  box-shadow: none;
}

.row.voice_abcd h5 {

  font-style: normal;
  font-weight: 500;
  font-size: 23px;
  margin-bottom: 5px;
  line-height: 38px;
  text-align: center;
  color: #000000;
}

.container-fluid.car_info {
  background: linear-gradient(180deg, rgba(0, 51, 153, 0.06) 0%, rgba(237, 28, 36, 0.06) 100%);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
}

.container-fluid.car_info h3 {
  font-weight: 500;
  font-size: 23px;
  margin-bottom: 0px;
  text-align: center;
  text-transform: capitalize;
  color: #F43130;
}

.container-fluid.car_info h5 {
  font-weight: 500;
  font-size: 25px;
  line-height: 45px;
  text-align: center;
  margin-bottom: 30px;
  text-transform: capitalize;
  color: #0D2F92;
}

.row.form_abcd {
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
}

.row.form_abcd {
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  justify-content: space-around;
  padding-top: 20px;
  padding-bottom: 20px;
}

.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: #e7f1ff08;
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}


/* Radio Buttons */

.row.form_abcd .button-group-pills .btn {
  background: white;
  border-color: #ED1C24;
  font-weight: 400;
  font-size: 16px;
  width: 100%;
  padding: 13px;
  margin-top: 10px;
  margin-bottom: 20px;
}

.row.form_abcd .btn.active:focus,
.btn:active:focus,
.btn:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.row.form_abcd .btn-default.active,
.btn-default:active {
  background-image: none;
}

.radiohide input[type=radio] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.row.form_abcd .button-group-pills .btn.active {
  color: #ffffff;
  box-shadow: none;
  border: 1px solid #003399;
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
  border-radius: 5px;
  background: #003399;
}

/* .row.form_abcd .button-group-pills .btn:hover {
  border-color: #ED1C24;
  background-color: transparent;
  color: #fff;
} */

.row.form_abcd ul li {
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 10px;
  color: #000000;
}

a.buttonactions {
  text-decoration: none;
  font-style: normal;
  cursor: pointer;
  font-weight: 500;
  padding: 12px 60px;
  font-size: 18px;
  border-radius: 100px;
  color: #FFFFFF;
  background: #ed1c24;
}

a.buttonactions1 {
  cursor: pointer;
  text-decoration: none;
  cursor: pointer;
  border-radius: 100px;
  font-style: normal;
  font-weight: 500;
  padding: 10px 60px;
  font-size: 18px;
  color: #FFFFFF;
  background: #ed1c24;
}

input.form-control {
  border-radius: 5px;
  height: 50px;
  font-weight: 500;
  border: 1px solid #ffffff;
  font-size: 18px;
  color: #000000;
  padding: 10px 10px;
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
  border-radius: 8px;
  height: 60px;
}

.input-group-text {
  padding: 10px 13px;
  background-color: #ffffff;
  border: 1px solid #ffffff;
  font-size: 18px;
}

.mb-5.input-group {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #ffffff;
  outline: 0;
  box-shadow: none;
}

i.fa.fa-chevron-left {
  margin-right: 15px;
}

i.fa.fa-chevron-right {
  margin-left: 15px;
}

.progress {
  margin-top: 10px;
  background: white;
  margin-bottom: 40px;
  border-radius: 40px;
}

.progress-bar {
  background-color: #003399;
  border-radius: 10px;
}

p.showcom {
  font-weight: 500;
  font-size: 16px;
  float: right;
  cursor: pointer;
  color: #ED1C24;
  margin-top: 0px;
  margin-bottom: 0px;
}

select.form-control {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 8px;
  height: 60px;
  width: 100% !important;
  font-weight: 600;
  border: none;
  font-size: 15px;
  color: #000000;
  margin-right: 0px;
  padding: 10px 20px;
}

.styled-select select {
  -moz-appearance: none;
  /* Firefox */
  -webkit-appearance: none;
  /* Safari and Chrome */
  appearance: none;
}

select {
  background: url("http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png") white no-repeat 98.5% !important;
  /* !important used for overriding all other customisations */
  background: url("http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png") white no-repeat calc(100% - 10px) !important;
  /* Better placement regardless of input width */
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 5px;
  border: 1px solid #dfdfdf;
  border-radius: 2px;
  margin-right: 2rem;
  padding: 1rem;
  padding-right: 2rem;
}

.row.custom_formss .form-control {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
}

.row.custom_formss .col-lg-2.col-md-12.col-sm-12.col-xs-12 {
  flex: 0 0 auto;
  width: 20%;
  margin-bottom: 20px;
}

.row.custom_formss12 .form-control {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
}

.row.custom_formss12 {
  align-items: center;
}

.row.custom_formss12 .col-lg-1.col-md-12.col-sm-12.col-xs-12 button {
  position: relative;
  margin: auto;
  display: block;
  top: -7px;
}

.row.custom_formss12 .col-lg-2.col-md-12.col-sm-12.col-xs-12 {
  flex: 0 0 auto;
  width: 22.9%;
  margin-bottom: 20px;
}

.item.aos-init.aos-animate a {
  text-decoration: none;
}

label.form-label.col-form-label.col-sm-4 {

  font-weight: 500;
  font-size: 18px;
  color: #000000;
}

button.submiit {
  display: inline-flex;
  padding: 10px 35px;
  position: relative;
  transition: .6s;
  background: #003399;
  border: 1px solid transparent;
  overflow: hidden;
  z-index: 1;
  justify-content: center;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 25px;
  text-align: center;
  color: #FFFFFF;
  align-items: center;
  border-radius: 100px;
}

p.ors {
  margin-bottom: 10px;
  margin-top: 10px;
  font-weight: 500;
  font-size: 20px;
  color: #000000;
}

button.submiit a {
  color: white;
  text-decoration: none;
}

.row.form_abcd ul {
  margin-bottom: 5px;
}

.container-fluid.car_info h5 {
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  text-transform: capitalize;
  color: #0D2F92;
  margin-bottom: 10px;
}

p.copyright12 {
  font-size: 13px;
}

.col-lg-12.bottom_footer p {

  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 25px;
  margin-bottom: 10px;
  text-align: center;
  color: #000000;
  margin-top: 14px;
}

.col-lg-4.social img {
  margin-right: 10px;
  margin-bottom: 10px;
}

ul.menu.menu--jdv-menu.nav {
  background-color: #003399;
  justify-content: center;
  border-top: white solid 1px;
}

ul.menu.menu--jdv-menu.nav li {
  text-decoration: none;
  color: #fff;
  padding: 10px 40px;
  border-right: 1px solid #fff;
}

ul.menu.menu--jdv-menu.nav li a {
  text-decoration: none;

  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  color: #FFFFFF;
}

.spinner {
  width: 150px;
  height: 150px;
  border: 6px solid;
  border-color: #003399 transparent #ff0418 transparent;
  border-radius: 50%;
  animation: spinner 3.5s linear infinite;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.body_loader {
  background: linear-gradient(to bottom right, magenta, cyan);
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
}


.nav-bg.sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #ffffff;
  z-index: 999;
  display: block;
  box-shadow: 0px 10px 20px 0px rgba(8, 0, 42, 0.08);
}

a.transfer_abcd.nav-link {
  font-weight: 500;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  color: #ED1C24;
}

.react-datepicker-wrapper {
  width: 90.3%;
}

.input-group {
  flex-wrap: inherit;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
}

p.mb-3.paragra {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
}

/* .container.quotes_filters.pt-5.pb-5 {
  padding-left: 0px;
  padding-right: 0px;
} */

.quotes_filters1 {
  background: linear-gradient(180deg, rgba(0, 51, 153, 0.06) 0%, rgba(237, 28, 36, 0.06) 100%);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
}

.Quotes_info1 {
  background: linear-gradient(180deg, rgba(0, 51, 153, 0.06) 0%, rgba(237, 28, 36, 0.06) 100%);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
}

/* .container.Quotes_info.pt-5.pb-5 {
  padding-left: 0px;
  padding-right: 0px;
} */

table.comparisions.table.table-lg.table-striped {
  border: 1px solid #000000;
}

.table-striped.comparisions>tbody>tr:nth-of-type(odd)>* {
  --bs-table-accent-bg: #DDE6FF;
  color: var(--bs-table-striped-color);
}

.table.comparisions>:not(caption)>*>* {
  background-color: #F2F6FF;
}

.table.comparisions>tbody {
  vertical-align: middle;
}

table.comparisions.table.table-lg.table-striped td {
  font-weight: 500;
  font-size: 13px;
  color: #000000;
}

.table>:not(caption)>*>* {
  padding: 15px 5px;
}

table.comparisions.table.table-lg.table-striped p {
  margin-bottom: 0px;
}

button.select {
  text-align: center;
  padding: 8px 19px;
  position: relative;
  border-radius: 100px;
  background: #003399;
  border: 1px solid transparent;
  font-weight: 400;
  font-size: 14px;
  color: #FFFFFF;
  margin-top: 5px;
}

.filterssas {
  background: #F5F8FF;
  word-wrap: break-word;
  padding: 10px 0px 0px;
  border: solid #0d2f92 1px;
}

button.removecompare {
  text-align: center;
  padding: 8px 20px;
  position: relative;
  border-radius: 100px;
  background: #ed1c25;
  border: 1px solid transparent;
  font-weight: 400;
  font-size: 14px;
  color: #FFFFFF;
  margin-top: 5px;
}

.table-container {
  overflow-x: auto;
  /* Enable horizontal scrollbar when content overflows */
}

.comparisons {
  width: 100%;
  /* Make the table expand to fill its container's width */
  white-space: nowrap;
  /* Prevent text wrapping within cells */
}

.filterssas h6 {
  font-weight: 500;
  margin-bottom: 0px;
  font-size: 13px;
  line-height: 1.4;
  color: #000000;
}

.filterssas .col-lg-5 {
  margin-bottom: 10px;
}

.filterssas .col-lg-7 {
  margin-bottom: 10px;
}

.filterssas.one {
  margin-bottom: 10px;
}

h4.personal.details {
  font-weight: 500;
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 30px;
  color: #0D2F92;
  text-align: center;
}

h4.personal.details i.fa.fa-edit {
  margin-left: 10px;
  color: #ED1C24;
}

h4.car.details {
  font-weight: 500;
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 30px;
  color: #0D2F92;
  text-align: center;
}

h4.car.details i.fa.fa-edit {
  margin-left: 10px;
  /* margin-right: -67%; */
  padding-top: 2%;
  color: #ED1C24;
}

.filters .form-check-input:checked {
  background-color: transparent;
  border-color: #ed1c24;
}

.filters .form-check-input:checked[type=checkbox] {
  background-image: url(./Image/correct.svg);
}

.abcds_abcs.form-check label {
  font-weight: 600;
  font-size: 14px;
  line-height: 25px;
  margin-left: 10px;
  color: #ED1C24;
  margin-top: 0px;
}

.form-check-input[type=checkbox] {
  position: relative;
  top: 0px;
}

.scroll_abcds {
  height: 1020px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.row.quotes_all p {
  font-weight: 600;
  font-size: 20px;
  color: #000000;
  margin-bottom: 0px;
}

h4.notess {
  font-weight: 600;
  font-size: 14px;
  line-height: 25px;
  margin-left: 10px;
  color: #ED1C24;
  margin-top: 0px;
}

.row.quotes_details {
  border-left: 1px solid #0D2F92;
  border-right: 1px solid #0D2F92;
  background: #F2F6FF;
  border-top: 1px solid #0D2F92;
  padding: 5px 10px 5px;
  align-items: center;
}

.row.quotes_details .col-lg-3 {
  text-align: center;
}

.form-check .form-check-input {
  float: initial;
}

button.submit_select {
  text-align: center;
  padding: 8px 20px;
  position: relative;
  border-radius: 100px;
  background: #003399;
  border: 1px solid transparent;
  font-weight: 400;
  font-size: 14px;
  color: #FFFFFF;
  margin-top: 5px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.abcds_abcs1.form-check label {
  font-weight: 500;
  font-size: 14px;
  margin-left: 5px;
  color: #000000;
  margin-top: 1px;
  margin-bottom: 5px;
}

.row.quotes_details h2 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 5px;
  line-height: 25px;
  text-align: center;
  color: #ED1C24;
}

ul.benefits li {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 25px;
  color: #ED1C24;
}

.row.quotes_details h4 {
  font-weight: 500;
  font-size: 14px;
  color: #000000;
}

.col-lg-3.action_abcd p {
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  margin-top: 10px;
  text-align: center;
  margin-bottom: 10px;
  color: #000000;
}

button.showadd_details {
  background: #0D2F92;
  border: 1px solid #0D2F92;
  padding: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  color: #FFFFFF;
}

.col-lg-6.abc {
  background: rgba(28, 135, 1, 0.06);
  border-left: 1px solid #0D2F92;
  padding: 10px 15px 15px;
}

.col-lg-6.cde {
  background: rgba(167, 0, 0, 0.06);
  border-right: 1px solid #0D2F92;
  padding: 10px 15px 10px;
}

ul.description li {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  color: #000000;
  line-height: 30px;
}

ul.description {
  margin-bottom: 0px;
  padding-left: 20px;
}

span.abcds_aud {
  font-weight: 500;
  font-size: 18px;
  line-height: 50px;
  color: #000000;
}

.accordian_modal_backdrop.accordion {
  margin-left: 4px;
  margin-right: 4px;
}

.accordian_modal_backdrop.accordion .accordion-item:last-of-type .accordion-button.collapsed {
  color: white;
  border-radius: 0px;
  padding-left: 10px;
  background: #003399;
}

.accordion-button:not(.collapsed) {
  color: #ffffff;
  padding-left: 10px;
}

.accordian_modal_backdrop.accordion .accordion-body {
  padding: 0px;
}

.container.quotes_filters.hide.pt-5.pb-5 .filtercheck {
  display: none;
}

.row.quotes_selectoption {
  justify-content: center;
}

.row.quotes_selectoption select.quotes_select.form-control {
  box-shadow: none;
  height: 45px;
  font-size: 13px;
  margin-top: 5px;
  margin-bottom: 15px;
  padding: 10px 10px;
  border: 1px solid #000000;
}

.rowabcds .row {
  margin: 0px;
}

.quotes_inner {
  margin-bottom: 20px;
}

.compare_list {
  background: #F5F8FF;
  padding: 20px 40px 30px 39px;
}

.comparelistcarousel {
  text-align: center;
  background: #FFFFFF;
}

.comparelistcarousel p {
  font-style: normal;
  font-weight: 400;
  padding: 5px 5px;
  font-size: 14px;
  height: 55px;
  line-height: 20px;
  text-align: center;
  margin-bottom: 10px;
  color: #000000;
}

.comparelistcarousel h4 {
  font-weight: 500;
  font-size: 18px;
  line-height: 15px;
  text-align: center;
  color: #121522;
}

.comparelistcarousel h5 {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #9A9C9E;
}

button.addtocomparebutton {
  padding: 12px 35px;
  background: #003399;
  border: 1px solid transparent;
  border-radius: 0;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  color: #FFFFFF;
}

.mt-5.compare_list h3 {
  font-weight: 500;
  font-size: 23px;
  line-height: 40px;
  color: #000000;
  margin-bottom: 15px;
}

.comparelistcarousel .fa {
  color: #FFC700;
  font-size: 18px;
  margin-bottom: 20px;
  margin-top: 10px;
  margin-right: 5px;
}

.comparelistcarousel span {
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #121522;
}

p.similarviews {
  margin-bottom: 0px;
  font-weight: 500;
  margin-top: 20px;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  text-decoration-line: underline;
  color: #ED1C24;
}

h3.disclaimerss {
  padding: 15px;
  margin-bottom: 0px;
  background: #ED1C24;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  color: #FFFFFF;
}

ul.benefits {
  margin-bottom: 0px;
  padding-left: 20px;
}

h3.abcd1234 {
  font-size: 22px;
  line-height: 1.3;
}

.col-lg-12.nopadding .row.form_abcd .button-group-pills .btn {
  margin-bottom: 0px;
  margin-top: 5px;
}

h4.form_textsa.cancel_policy {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #333333;
}

h4.form_textsa.cancel_policy:before {
  top: 37px;
}

.container.Quotes_info1212.pt-4.pb-4 .filtercheck {
  display: none;
}

.pay_Details {
  padding: 20px 35px;
  background: #F8FCFF;
  border: 1px solid #D7E5E7;
  border-radius: 4px;
  margin-bottom: 20px;
}

.pay_Details1 {
  padding: 24px 15px 10px;
  background: #F8FCFF;
  border: 1px solid #D7E5E7;
  border-radius: 4px;
  text-align: center;
  margin-bottom: 20px;
}

.pay_Details p {
  font-weight: 500;
  margin-bottom: 10px;
  font-size: 15px;
  color: #333333;
}

.pay_Details1 h6 {
  font-weight: 500;
  text-align: left;
  font-style: normal;
  margin-bottom: 15px;
  font-size: 16px;
  color: #333333;
}

.pay_Details1 p {
  font-weight: 500;
  font-size: 15px;
  color: #000000;
  margin-bottom: 0px;
}

.row.form_abcd1 {
  justify-content: space-around;
}

button.button-add {
  font-size: 14px;
  padding: 12px 40px;
  position: relative;
  background: #003399;
  margin-right: 10px;
  color: white;
  border: 1px solid transparent;
  overflow: hidden;
  z-index: 1;
  border-radius: 0;
}

button.button-submit {
  font-size: 14px;
  padding: 12px 40px;
  position: relative;
  background: #003399;
  margin-right: 10px;
  color: white;
  border: 1px solid transparent;
  overflow: hidden;
  z-index: 1;
  border-radius: 0;
}

button.button.add {
  color: white;
  padding: 10px 30px;
  background: #003399;
  border: none;
  margin-right: 30px;
  border-radius: 100px;
}

button.button.submit {
  color: white;
  padding: 10px 30px;
  background: #003399;
  border: none;
  margin-right: 30px;
}

.button-section1 {
  display: flex;
  margin: auto;
  justify-content: center;
}

button.button.remove {
  margin-bottom: 30px;
  width: 60%;
  padding: 12px 22px;
  position: relative;
  transition: .6s;
  background: #003399;
  color: white;
  border: 1px solid transparent;
  font-size: 14px;
  overflow: hidden;
  z-index: 1;
  border-radius: 0;
}

.col-lg-8.col-md-12.col-sm-12.col-xs-12.text_all_abcds h3 {

  font-style: normal;
  margin-bottom: 15px;
  font-weight: 500;
  font-size: 26px;
  color: #000000;
}

.col-lg-8.col-md-12.col-sm-12.col-xs-12.text_all_abcds h4 {
  font-style: normal;
  font-weight: 500;
  margin-bottom: 20px;
  font-size: 22px;
  color: #000000;
}

label.form-check-label.text-abcdq {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  color: #000000;
  margin-bottom: 10px;
  margin-left: 10px;
}

.sidebar {
  background: #fff;
  border: solid #003399 1px;
  box-shadow: 4px 4px 21px rgba(76, 64, 247, .2);
  color: #121212;
  outline: none;
  padding: 0;
}

.sidebar a {
  color: #000;
  cursor: pointer;
  display: block;
  font-size: 15px;
  font-weight: 600;
  padding: 16px;
  text-decoration: none;
}

.sidebar {
  position: relative;
}

.sidebar span {
  position: absolute;
  right: 15px;
}

.sidebar a.active {
  background-color: #ED1C24;
  color: #fff;
}

.sidebar a:hover:not(.active) {
  background-color: #013194;
  color: #fff;
}

@media screen and (max-width:700px) {
  .sidebar {
    height: auto;
    position: relative;
    width: 100%;
  }
}

@media screen and (max-width:400px) {
  .sidebar {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
}

.my_profile {
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  padding: 20px 25px 10px;
}

.my_profile .input-group {
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
}

.policyrenewals .row {
  margin-left: 0px;
  margin-right: 0px;
}

.policyrenewals .rowabcds {
  background: #F2F6FF;
  border: solid #003398 1px;
  border-top: none;
}

.policyrenewals ul.description li {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  color: #000000;
  line-height: 2;
}

.policyrenewals ul.description {
  margin-bottom: 0px;
  padding-left: 40px;
  list-style: none;
}

.policyrenewals span.abcds_aud {
  font-size: 15px;
  line-height: 30px;
}

button.cancelpolicynew {
  height: 45px;
  margin-top: 160px;
  font-weight: 600;
  padding: 10px 15px;
  margin-left: 10px;
  border: solid #e71b24 1px;
  background: transparent;
  color: #e71b24;
  font-size: 15px;
}

.row.policy_documents {
  padding-top: 10px;
  align-items: center;
  border-top: solid #003399 1px;
}

.row.policy_documents p {
  font-weight: 500;
  font-size: 15px;
  margin-bottom: 10px;
  letter-spacing: 0.3px;
  color: #000000;
}

button.uploaddocus {
  font-weight: 500;
  float: right;
  margin-bottom: 10px;
  border: #003399 solid 1px;
  background: transparent;
  font-size: 15px;
  padding: 5px 15px;
  color: #003195;
}

button.claims_abcd1 {
  margin-top: 10px;
  border-radius: 100px;
  margin-bottom: 20px;
  font-weight: 600;
  padding: 10px 15px;
  margin-left: 10px;
  border: solid #e71b24 1px;
  background: transparent;
  color: #e71b24;
  font-size: 15px;
}

button.claims_abcd {
  margin-top: 10px;
  border-radius: 100px;
  margin-bottom: 20px;
  font-weight: 600;
  padding: 10px 25px;
  margin-left: 10px;
  border: solid #003399 1px;
  background: #003399;
  color: #ffffff;
  font-size: 15px;
}

i.fa.fa-copy {
  cursor: pointer;
}

.Quotes_info1 .row {
  margin-left: 5px;
  margin-right: 5px;
}

.Selectedinfo .row {
  margin-left: 5px;
  margin-right: 5px;
}

.quotes_filters1.comparision .row {
  margin-left: 5px;
  margin-right: 5px;
}

p.filtercheck {
  font-weight: 500;
  font-size: 19px;
  margin-bottom: 5px !important;
  color: #000000;
}

.container-fluid.car_info.pt-4.pb-4.thankyou form.card-content {
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: 1px 1px 2rem rgba(0, 0, 0, .3);
  padding: 0 0.5rem 2rem;
  text-align: center;
}

.container-fluid.car_info.pt-4.pb-4.thankyou p {
  font-weight: 500;
  font-size: 25px;
  text-transform: capitalize;
  color: #F43130;
}

.mb-4.input-group select.form-control {
  box-shadow: none !important;
}

h6.indicative {
  font-weight: 500;
  font-size: 14px;
  padding-top: 20px;
  text-align: center;
  color: #0D2F92;
  padding-bottom: 20px;
}

img.image_profile {
  width: 25px;
  height: 25px;
  margin-right: 5px;
  position: relative;
}

.quotestables .table>:not(caption)>*>* {
  font-size: 12px;
  padding: 10px 10px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
}


.colnopadding.additional h4 {
  margin-bottom: 20px;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  color: #000000;
}

.abcdsfloat h3 {
  font-family: Poppins;
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 5px;
  line-height: 21px;
  letter-spacing: 0em;
  color: #ED1C24;
  margin-top: 30px;
}

.abcdsfloat h5 {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  margin-top: 10px;
  line-height: 30px;
  letter-spacing: 0em;
  color: #000000;
}

h1.taxzesd {
  padding: 8px 0px;
  margin-right: 4px;
  font-family: Poppins;
  font-size: 10px;
  font-weight: 500;
  margin-left: 4px;
  margin-bottom: 15px;
  color: #FFFFFF;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: center;
  background: #F43130;
}

input.coupons {
  border: solid #0D2F92 1px;
  padding: 10px;
}

button.hjkbfhdb {
  background: #0D2F92;
  color: white;
  padding: 10px 30px;
  border: solid 1px #0d2f92;
}


span.call_abcdsas123 {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  margin-left: 6px;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
}

.row.quotes_selectoption span {
  font-size: 13px;
  font-weight: 600;
}

.rowabcds .row {
  margin: 0px;
}

a.compares123 {
  color: black;
  text-decoration: none;
  bottom: 240px;
  z-index: 999;
  font-size: 18px;
  position: fixed;
  padding: 10px 10px;
  border-radius: 10px;
  right: 33px;
  font-weight: bold;
}

button.gotodashboard {
  display: block;
  margin: auto;
  padding: 10px 40px;
  position: relative;
  transition: .6s;
  background: #003399;
  color: white;
  border: 1px solid transparent;
  font-size: 14px;
  overflow: hidden;
  z-index: 1;
  border-radius: 100px;
}

img.compare_123 {
  width: 60px;
}

.custom_aside p {
  color: #666666;
  font-size: 12px;
  margin-bottom: 5px;
}

.selectedSpecification {
  background: #003096;
  border-radius: 5px;
  margin-right: 5px;
  position: relative;
  padding-bottom: 10px;
  padding-top: 5px;
}

.selectedSpecification span {
  position: absolute;
  right: 5px;
  padding: 0px 5px;
  top: 1px;
  color: #fff;
  cursor: pointer;
}

.selectedSpecification h6 {
  color: #fff;
  font-size: 14px;
  padding: 9px 25px 1px 15px;
  margin: 0px;
  white-space: nowrap;
  max-width: 90%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.selectedSpecification p {
  margin: 0px;
  color: #fff;
  font-size: 14px;
  padding: 0px 15px;
}

.buttonadjust {
  margin-top: 20px;
  text-align: center;
}

a.cancelabcd {
  text-decoration: none;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  border: 1px solid #FF0418;
  padding: 10px 30px;
  background-color: #FF0418;
  margin-right: 10px;
}

a.compareabcd {
  text-decoration: none;
  border-radius: 10px;
  color: #fff;
  border: 1px solid #003096;
  padding: 10px 30px;
  background-color: #003096;
}

button.button_next {
  text-decoration: none;
  font-style: normal;
  font-weight: 500;
  padding: 12px 60px;
  font-size: 18px;
  border-radius: 100px;
  color: #FFFFFF;
  background: #ed1c24;
  border: none;
}

button.cancelabcd {
  text-decoration: none;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  border: 1px solid #FF0418;
  padding: 10px 30px;
  background-color: #FF0418;
  margin-right: 10px;
}

button.compareabcd {
  text-decoration: none;
  border-radius: 10px;
  color: #fff;
  border: 1px solid #003096;
  padding: 10px 30px;
  background-color: #003096;
}

.filterssas.one select {
  height: 40px;
  font-size: 13px;
  padding-left: 10px;
}

.filterssas.one .row {
  align-items: center;
}

.filterssas.two.mb-5 input {
  font-size: 13px;
  height: 40px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
}

.filterssas.two.mb-5 .row {
  align-items: center;
}

button.profileupadtes {
  text-align: center;
  padding: 8px 30px;
  position: relative;
  border-radius: 100px;
  background: #ed1c24;
  border: 1px solid transparent;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 15px;
  color: #FFFFFF;
  margin-top: 5px;
}

i.fa.fa-edit {
  cursor: pointer;
}

.react-datepicker-wrapper {
  width: 100%;
}

span.terms_condition {
  display: block;
  padding-top: 10px;
  font-weight: 500;
  font-size: 10px;
}

span.terms_condition a {
  font-weight: 600;
  text-decoration: none;
  color: #003399;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.comparelistcarousel img {
  object-fit: contain;
  height: 120px;
  padding: 15px;
}

.help-block {
  display: block;
  margin-top: 5px;
  margin-bottom: 10px;
  color: #737373;
}

.tooltip-inner {
  max-width: 200px;
  padding: 3px 8px;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 4px;
}

.tooltip-inner {
  max-width: 200px;
  padding: 3px 8px;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 4px;
}

.help-block:hover .intro {
  background-color: blue;
  color: #fff;
  border: 1px solid transparent;
}

.help-block .intro {
  background-color: #f5f4f4;
  padding: 5px 11px;
  border-radius: 70px;
  border: 1px solid #e2e0e0;
  font-size: 14px;
  font-weight: bold;
  color: #000;
}

.help-block .tooltip-wrapper:hover span.tooltip-main {
  top: -10px;
  display: inline;
  margin-left: 15px;
}

span.tooltip-wrapper {
  margin-left: 15px;
}

.help-block {
  cursor: pointer;
  display: contents;
}

.help-block span.tooltip-main {
  display: none;
  position: absolute;
  top: -17px;
  background-color: #F9F1E4;
  border: 1px solid #e4cfac;
  color: #696969;
  font-size: 14px;
  width: auto;
  padding: 10px;
  font-family: cabinregular;
  border-radius: 4px;
  width: 200px;
  width: max-content;
  width: -webkit-max-content;
  width: -ms-max-content;
  width: -moz-max-content;
}

.help-block span.tooltip-main:after {
  content: '';
  position: absolute;
  left: -45px;
  top: -10px;
  width: 0;
  height: 0;
  border-left: 14px solid transparent;
  border-right: 14px solid transparent;
  border-top: 14px solid #f9f1e4;
  clear: both;
  min-height: 62px;
  transform: rotate(90deg);
}

.help-block span.tooltip-main .tooltip-inner {
  background-color: #F9F1E4;
  color: #505050;
  font-family: cabinsemibold;
  padding-left: 0px;
}

@media (max-width:768px) {
  .help-block {
    display: none;
  }
}

@media (max-width:991px) {
  .description.help-block span.tooltip-main:after {
    left: 66px;
    top: -51px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #f9f1e4;
    min-height: 52px;
    transform: rotate(180deg);
  }

  .description.help-block span.tooltip-main {
    top: 35px;
    left: -66px;
    font-size: 12px;
    width: 95px;
    min-height: 50px;
    padding: 4px;
  }
}

@media (max-width:768px) {
  .description.help-block .intro {
    padding: 2px 8px;
  }

  .description.help-block span.tooltip-main {
    left: -75px;
    padding: 4px 0px;
    min-height: auto;
  }

  .description.help-block span.tooltip-main .tooltip-inner {
    padding-right: 0px;
  }

  .description.help-block {
    right: -26px;
  }
}

@media (max-width:480px) {
  .description.help-block {
    display: none !important;
  }
}

@media (max-width:768px) {
  .description.help-block {
    display: none !important;
  }
}

.row.overalldetails {
  margin: 0px 5px;
}

.side-by-side-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  /* Adjust the gap between items as needed */
}

.side-by-side-item {
  display: flex;
  align-items: center;
}

.payemt-icon img {
  width: 100%;
}

#loading {
  position: absolute;
  right: 50%;
  margin-top: 5px;
  width: 50px;
  height: 50px;
  border: 6px solid rgb(0 51 153);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 3s ease-in-out infinite;
  -webkit-animation: spin 3s ease-in-out infinite;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

li.label_home {
  height: 50px;
}

.filterssas.one input {
  height: 40px;
  font-size: 13px;
  padding-left: 10px;
}

i.fa.fa-check {
  margin-left: 15%;
}

.PhoneInput {
  display: flex;
  align-items: center;
  border-radius: 5px;
  height: 50px;
  font-weight: 500;
  border: 1px solid #ffffff;
  font-size: 18px;
  color: #000000;
  padding: 10px 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  height: 60px;
}

input.PhoneInputInput {
  outline: none;
  font-weight: 500;
  font-size: 18px;
  padding-left: 5px;
  color: #000000;
  border: none
}

.rangeslider.rangeslider-horizontal {
  margin-bottom: 5px;
}

ul.rangeslider__labels {
  margin-bottom: 0px;
}

.value {
  font-weight: 600;
  font-size: 15px;
  color: #003399;
}

.rangeslider-horizontal .rangeslider__handle {
  width: 20px !important;
  height: 20px !important;
}

.rangeslider-horizontal .rangeslider__handle:after {
  width: 9px !important;
  height: 9px !important;
  top: 5px !important;
  left: 5px !important;
}

.rangeslider-horizontal {
  height: 9px !important;
  border-radius: 10px !important;
}

.rangeslider-horizontal .rangeslider__fill {
  background-color: #0d2f92 !important;
}

.mortageee.form-check input {
  margin-right: 10px;
}

.mortageee.form-check {
  margin-right: 20px;
}

a.savechanges {
  background: #0D2F92;
  color: white;
  padding: 8px 40px;
  border: solid 1px #0d2f92;
  text-decoration: none;
  border-radius: 100px;
}

a.savechanges {
  background: #0D2F92;
  color: white;
  cursor: pointer;
  padding: 10px 30px;
  border: solid 1px #0d2f92;
  text-decoration: none;
  border-radius: 100px;
}

p.paragraph {
  font-weight: 500;
  font-size: 13px;
  text-transform: capitalize;
  color: #000000;
  margin-bottom: 5px;
}

.modal-title.h4 {
  font-size: 20px;
  font-weight: 600;
  color: #F43130;
}

a.buttonactions.disabled {
  background: grey;
}

.mb-4.input-group {
  background: white;
}

.d-flex.labelssss label {
  margin-left: 10px;
  font-weight: 600;
  font-size: 15px;
  color: #000000;
  margin-top: 1px;
}

a.termscond {
  text-decoration: none;
  cursor: pointer;
}

.form-control:disabled {
  opacity: 1;
  background: #e6e6e6;
  border: none;
  border-radius: 0px;
}

span.addon12.input-group-text {
  background: #e0e0e0;
  border: none;
}

i#btd:hover {
  background: #A8181C;
  text-align: center;
  color: white;
  margin-top: 10px;
  padding: 10px;
  margin-bottom: 0px;
  cursor: pointer;
}

.form-control.phone_numberas.PhoneInput {
  margin-bottom: 0px;
}

i#btd span {
  position: relative;
  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
  top: -1px;
  margin-left: 5px;
}

i#btd {
  background: #ED2A30;
  border-radius: 5px;
  text-align: center;
  color: white;
  margin-top: 10px;
  padding: 10px;
  margin-bottom: 0px;
  cursor: pointer;
}

button.disablebtn {
  text-decoration: none;
  font-style: normal;
  font-weight: 500;
  background: grey;
  padding: 12px 60px;
  font-size: 18px;
  border-radius: 100px;
  color: #FFFFFF;
  border: none;
  cursor: not-allowed;
}

button.buttonactions {
  text-decoration: none;
  font-style: normal;
  font-weight: 500;
  padding: 10px 30px;
  font-size: 18px;
  border-radius: 100px;
  color: #FFFFFF;
  background: #ed1c24;
  border: none;
}

.reachus_contact {
  margin-bottom: 40px;
}

/* .form-control:focus {
  color: #212529;
  background-color: #fff0;
  border-color: #ffffff00;
  outline: 0;
  box-shadow: none;
} */

button.submit_query {
  color: #fff;
  border: none;
  border-color: #27346a;
  font-size: 14px;
  padding: 13px 20px;
  background: #003399;
  box-shadow: 0px 8px 8px rgba(13, 47, 146, 0.2);
  border-radius: 8px;
}

.reachus_contact input {
  height: 25px;
  background: transparent;
  margin-left: 15px;
  border: solid #00339900 1px;
  border-radius: 1px;
}

#offers .card {
  padding: 10px;
  border-radius: 20px;
  background: #0d2f92b8;
  border: none;
  color: #fff;
  height: 350px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
}

#offers .card h1 {
  font-weight: 600;
  font-size: 34px;
  margin-bottom: 0px;
}

#offers .card span {
  font-size: 20px;
  padding-top: 10px;
}

#offers h2 {
  line-height: 1.5;
  font-size: 19px;
  text-align: center;
}

#offers .image {
  position: absolute;
  opacity: .1;
  left: 0;
  top: 0
}

#offers .image2 {
  position: absolute;
  bottom: 0;
  right: 0;
  opacity: .1
}

.privacypolicy p {
  margin-top: 20px;
  font-size: 15px;
}

.contact-info {
  display: inline-block;
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
}

.contact-info-icon {
  margin-bottom: 15px;
}

.contact-info-item {
  background: white;
  padding: 30px 0px;
  box-shadow: 0px 10px 20px 0px rgba(8, 0, 42, 0.08);
}

.contact-page-sec .contact-page-form h2 {
  color: #071c34;
  text-transform: capitalize;
  font-size: 22px;
  font-weight: 700;
}

.contact-page-form .col-md-6.col-sm-6.col-xs-12 {
  padding-left: 0;
}

.contact-page-form.contact-form input {
  margin-bottom: 5px;
}

.contact-page-form.contact-form input[type="submit"] {
  background: #071c34;
  width: 150px;
  border-color: #071c34;
}

.contact-info-icon i {
  font-size: 50px;
  color: #ee1d24;
}

.contact-info-text p {
  margin-bottom: 0px;
}

.contact-info-text h2 {
  color: #013299;
  font-size: 22px;
  text-transform: capitalize;
  font-weight: 600;
  margin-bottom: 10px;
}

.contact-info-text span {
  color: #013299;
  font-size: 16px;
  display: inline-block;
  width: 100%;
  font-weight: 500;
}

select.react-datepicker__month-select {
  background: none !important;
  padding: 5px 10px !important;
}

select.react-datepicker__year-select {
  background: none !important;
  padding: 5px 10px !important;
}

.faqacc.accordion.accordion-flush button {
  color: black;
}

img.scroll-to-top-button {
  position: fixed;
  bottom: 100px;
  right: 20px;
  width: 60px;
  cursor: pointer;
  border: solid #003399 2px;
  z-index: 9;
  box-shadow: 0px 10px 20px 0px rgba(8, 0, 42, 0.08);
  border-radius: 100px;
}

select.quotes_select.form-control.invisibl {
  margin-bottom: 20px;
}

h6.companyname {
  line-height: 1.5;
  padding-top: 10px;
  font-weight: 600;
  font-size: 14px;
  color: #003399;
  margin-bottom: 0px;
}

img.comp_logoas {
  width: 80px;
  height: 80px;
  object-fit: contain;
}

.col-lg-9.textabcd h3 {
  font-size: 20px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0em;
  margin-bottom: 5px;
}

.col-lg-9.textabcd p {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0em;
  margin-bottom: 5px;
}

.col-lg-9.textabcd h2 {
  font-size: 13px;
  font-weight: 600;
}

.row.abcdsrows {
  margin-top: 35px;
  margin-bottom: 35px;
}

.row.abcdsrows img {
  margin: auto;
  display: block;
}

.mb-3.formabcdsawee.input-group select {
  box-shadow: none !important;
}

.buttonsubmit {
  margin-top: 35px;
}

textarea.form-control {
  border: none;
}

.row.form_textsa .col-lg-5 {
  padding-left: 25px;
  padding-right: 25px;
}

.emergency {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 15px;
}

.emergency i {
  margin-right: 10px;
}

.emergency.notes {
  margin-top: 40px;
}

button.btn.btn-gotodashboard {
  display: inline-flex;
  padding: 10px 40px;
  position: relative;
  transition: .6s;
  background: #003399;
  color: white;
  border: 1px solid transparent;
  font-size: 12px;
  overflow: hidden;
  z-index: 1;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
}

button.btn.btn-gotodashboard12 {
  display: inline-flex;
  padding: 10px 40px;
  position: relative;
  transition: .6s;
  background: #ed2a30;
  color: white;
  border: 1px solid transparent;
  font-size: 12px;
  overflow: hidden;
  z-index: 1;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
}

.col-lg-3.quotesmobile img {
  width: 130px;
}

.col-lg-3.action_abcd img {
  width: 150px;
}

input.textancd.col-lg-2.col-md-12.col-sm-12.col-xs-12 {
  margin-right: 12px;
  font-weight: 500;
  border: 1px solid #ffffff;
  font-size: 18px;
  margin-bottom: 20px;
  width: 28.5%;
  color: #000000;
  padding: 10px 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  height: 60px;
}

i.fa.fa-trash.deletes {
  padding: 15px 25px;
  text-decoration: none;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  cursor: pointer;
  border-radius: 100px;
  color: #FFFFFF;
  background: #ed1c24;
  margin-left: 15px;
}

/* check */

.hover-effect {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.hover-text {
  position: absolute;
  top: -50px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 5px;
  border-radius: 5px;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.hover-effect:hover .hover-text {
  opacity: 1;
}

.owl-carousel.mt-5.partners_img.aos-init.owl-loaded.owl-drag.aos-animate .rating {
  text-align: center;
  margin-top: 5px;
  cursor: pointer;
}

.partners_img .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  padding: 12px 20px;
  font-weight: 700;
  border: none;
  border-bottom: solid #0065ff 4px;
  border-color: #0065ff;
  color: #0065ff;
  font-size: 15px;
}

.nav-tabs .nav-link {
  padding: 12px 20px;
  font-weight: 600;
  border: none;
  color: #000000;
  font-size: 15px;
}

.rating-table {
  overflow: auto;
  max-height: 500px;
  border-radius: 0 0 8px 8px;
  padding: 10px;
}

p.subHeadTxt {
  margin-bottom: 10px;
  font-size: 13px;
  line-height: 18px;
  padding-left: 8px;
}

.rating-table table {
  border-collapse: collapse;
}

.rating-table table thead tr th {
  color: white !important;
}

.rating-table table thead tr {
  background-color: #003399;
}

.rating-table table tbody tr td,
.rating-table table thead tr th {
  font-size: var(--font-table);
  line-height: normal;
  font-weight: 500;
  color: #000;
  white-space: nowrap;
  padding: 15px 58px;
  border: 1px solid #013399;
}

.rating-table table tbody tr td {
  line-height: 24px;
  white-space: normal;
  font-weight: 300;
  padding: 15px 25px;
  text-align: center;
  vertical-align: middle;
}

.rating-table table tbody tr:nth-child(2n) td {
  background-color: #ffffff;
}

.rating-table table tbody tr td:first-child {
  background-color: #ffffff;
  font-weight: 500;
}

@media (max-width:991px) {
  .rating-table {
    max-height: 400px;
    overflow: auto;
  }
}

@media (max-width:768px) {
  .rating-table {
    --font-table: 12px;
  }

  p.subHeadTxt {
    line-height: 16px;
  }

  p.subHeadTxt {
    font-size: 12px;
  }

  .rating-table table tbody tr td,
  .rating-table table thead tr th {
    white-space: normal;
    padding: 11px 10px;
  }

  .rating-table table tbody tr td {
    --font-table: 10px;
    font-weight: 400;
  }

  .rating-table table tbody tr td:last-child {
    text-align: left;
  }
}


::-webkit-scrollbar-thumb {
  background-color: #0177af;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #0f85df;
}

::-webkit-scrollbar {
  background-color: #f6f6f6;
  -webkit-appearance: none;
  width: 6px;
  height: 6px;
  border-radius: 20px;
}

::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: rgba(0, 0, 0, .5);
  -webkit-box-shadow: 0 0 1px hsla(0, 0%, 100%, .5);
  -moz-box-shadow: 0 0 1px hsla(0, 0%, 100%, .5);
  box-shadow: 0 0 1px hsla(0, 0%, 100%, .5);
}

input.textancd.col-lg-2.col-md-12.col-sm-12.col-xs-12 {
  border: 1px solid #fff;
  border-radius: 8px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, .25);
  color: #000;
  font-size: 18px;
  font-weight: 500;
  height: 60px;
  margin-bottom: 20px;
  margin-right: 12px;
  padding: 10px;
  width: 22.9%;
}

.flip-box-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip-box:hover .flip-box-inner {
  transform: rotateY(180deg);
}

.flip-box-front,
.flip-box-back {
  backface-visibility: hidden;
}

.flip-box-back {
  top: 0;
  transform: rotateY(180deg);
  position: absolute;
}

span.add_valuess {
  margin-right: 30px;
  position: relative;
  top: -5px;
  margin-left: 10px;
}

.col-lg-12.nopadding {
  margin-top: 20px;
}

.react-multiple-carousel__arrow--right {
  right: calc(0% + 0px) !important;
}

.react-multiple-carousel__arrow--left {
  left: calc(0% + 0px) !important;
}

.mt-5.mb-5.helpsandtips.container .accordion-button:not(.collapsed) {
  color: #ffffff;
  padding-left: 10px;
  background: #003399;
}

.mt-5.mb-5.helpsandtips.container button.accordion-button.collapsed {
  padding-left: 10px;
}

.mt-5.mb-5.helpsandtips .accordion-item {
  margin-bottom: 20px;
}

.mt-5.mb-5.helpsandtips .accordion-item:not(:first-of-type) {
  border-top: solid #dee2e6 1px;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


.card-body.thankyoupage {
  background-color: #F2F6FF;
  border: 1px solid #003399;
}


.card-body.thankyoupage .form-control {
  margin-bottom: 10px;
  border: solid #003399 1px;
  height: 45px;
  margin-top: 5px;
}

.thankyoupage label.form-label {
  font-size: 14px;
}

.drop-container {
  position: relative;
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px;
  padding: 20px;
  border-radius: 10px;
  border: 2px dashed #555;
  color: #444;
  cursor: pointer;
  transition: background .2s ease-in-out, border .2s ease-in-out;
}

.drop-container:hover {
  background: #eee;
  border-color: #111;
}

.drop-container:hover .drop-title {
  color: #222;
}

.drop-title {
  color: #444;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  transition: color .2s ease-in-out;
}

.file-input-container {
  position: relative;
  display: inline-block;
}

.reset-button {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  color: red;
  /* Choose your desired color */
}

.reset-button:hover {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  background: red;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  color: white;
  /* Choose your desired color */
}


.react-datepicker-wrapper {
  display: block !important;
  width: auto !important;
}


input.datepickerss {
  width: 100% !important;
}

select.textancd.col-lg-2.col-md-12.col-sm-12.col-xs-12 {
  border: 1px solid #fff;
  border-radius: 8px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, .25);
  color: #000;
  font-size: 18px;
  font-weight: 500;
  height: 60px;
  margin-bottom: 20px;
  margin-right: 12px;
  padding: 10px;
  width: 20.9%;
}

.page-header-bg-travel {
  background-image: linear-gradient(rgb(0 0 0/60%), rgb(0 0 0/60%)),
    url(../src/Image/Banner/section3.jpg);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: initial;
  z-index: -1;
}

.page-header-bg-home {
  background-image: linear-gradient(rgb(0 0 0/60%), rgb(0 0 0/60%)),
    url(../src/Image/Banner/section5.jpg);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: initial;
  z-index: -1;
}

.starlabel label:after {
  content: " *";
  color: red;
  font-size: large;
}

.page-header-bg-medical {
  background-image: linear-gradient(rgb(0 0 0/60%), rgb(0 0 0/60%)),
    url(../src/Image/Banner/section6.jpg);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: initial;
  z-index: -1;
}

.page-header-bg-groupmedical {
  background-image: linear-gradient(rgb(0 0 0/60%), rgb(0 0 0/60%)),
    url(../src/Image/Banner/section7.jpg);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: initial;
  z-index: -1;
}

.page-header-bg-yacht {
  background-image: linear-gradient(rgb(0 0 0/60%), rgb(0 0 0/60%)),
    url(../src/Image/Banner/section4.jpg);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: initial;
  z-index: -1;
}

.manageexcess {
  margin-top: 20px;
  font-size: 10px;
}

input.couponcode {
  font-family: Poppins;
  font-size: 15px;
  text-align: end;
  font-weight: 600;
  letter-spacing: 0em;
  color: #ED1C24;
  margin-top: 0px;
}

.filterdate {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 8px;
  height: 60px;
  width: 100% !important;
  font-weight: 600;
  border: none;
  font-size: 15px;
  color: #000000;
  margin-right: 0px;
  padding: 10px 20px;
}


.row.custom_formss1234 .col-lg-2.col-md-12.col-sm-12.col-xs-12 {
  flex: 0 0 auto;
  width: 20%;
  margin-bottom: 20px;
}

.row.custom_formss1234 .form-control {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
}

.row.custom_formss1234 {
  align-items: center;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}



.col-lg-3.action_abcd span {
  cursor: pointer;
  font-weight: bold;
  font-size: 14px;
  line-height: 15px;
  margin-top: 10px;
  text-align: center;
  margin-bottom: 10px;
  color: #ED1C24;
}

.networkstooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
  width: 100%;
  text-decoration: none;
  background-color: #ED1C24;
}

.popover-header {
  background-color: #ED1C24;
  color: white;
  font-weight: 600;
  font-size: 14px;

}

.popover-body {
  text-decoration: none;
  border: 2px solid #ED1C24
}

.popover-body li {
  text-decoration: none;

}

.bs-popover-top>.popover-arrow::after {
  border-top-color: #ED1C24;
}

.plan a {
  font-size: 11px;
  text-decoration: none;
  padding: 15px;
}

.mb-4.dateeeee.input-group .react-datepicker-wrapper {
  width: 100% !important;
  display: block !important;
}

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  /* Transparent black */
  z-index: 9999;
  /* Ensure it appears on top of other elements */
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.content.loading {
  opacity: 0.5;
  /* Reduce opacity of content when loading */
  pointer-events: none;
  /* Disable pointer events on content when loading */
}

button.buttonred {
  margin-right: 10px;
  padding: 10px 25px;
  background: #ed2a30;
  color: white;
  border: 1px solid transparent;
  font-size: 12px;
  border-radius: 5px;
  box-shadow: 0px 4px 4px 0px #00000040;
}

button.buttonblue {
  margin-right: 10px;
  padding: 10px 25px;
  background: #0D2F92;
  color: white;
  border: 1px solid transparent;
  font-size: 12px;
  border-radius: 5px;
  box-shadow: 0px 4px 4px 0px #00000040;
}

button.buttongreen {
  margin-right: 10px;
  padding: 10px 25px;
  background: #08900a;
  color: white;
  border: 1px solid transparent;
  font-size: 12px;
  border-radius: 5px;
  box-shadow: 0px 4px 4px 0px #00000040;
}

.group-medicalss {
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 30px;
}

.selectoption.row .form-select {
  font-size: 13px;
  background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png) #f4dfe0 no-repeat calc(100% - 10px) !important;
  border: 1px solid #ED1C24;
  background-size: 10px !important;
  height: 40px;
}

a.upload {
  color: #0F6901;
  font-family: 'Poppins';
  font-size: 15px;
  font-weight: 700;
  text-align: center;
}

.selectoption.row .col-lg-2 {
  width: 20%;
  max-width: 20%;
}

table.groupmedi.table.table-striped.table-bordered.table-hover thead th {
  background: #0D2F92;
  color: white !important;
  font-size: 12px;
}

table.groupmedi.table.table-striped.table-bordered.table-hover>:not(caption)>*>* {
  padding: 13px 5px;
  font-size: 14px;
  color: #3C4852;
  font-weight: 500;
  width: 0px;
}

.selectoption.row {
  margin-top: 15px;
  margin-bottom: 25px;
  justify-content: space-around;
}

table.groupmedi.table.table-striped.table-bordered.table-hover {
  text-align: center;
}

input.tbcheck {
  width: 18px;
  height: 18px;
  position: relative;
  top: 5px;
  margin-right: 15px;
}

table.groupmedi>tbody>tr:nth-of-type(even)>* {
  background-color: #E3F7FF !important;
}

table.groupmedi>tbody>tr:nth-of-type(odd)>* {
  background-color: #F4FCFF !important;
}

.member input {
  flex-wrap: inherit;
  border-radius: 8px;
  box-shadow: 0px 4px 4px 0px #00000040 !important;
  background: white;
  height: 55px;
  margin-bottom: 30px;
  font-size: 14px;
}

.member select {
  flex-wrap: inherit;
  border-radius: 8px;
  height: 55px;
  box-shadow: 0px 4px 4px 0px #00000040 !important;
  background: white;
  margin-bottom: 30px;
}

.member label {
  color: #000000;
  font-family: 'Poppins';
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 1px;
  margin-bottom: 15px;
}

.member h4 {
  background: #0D2F92;
  padding: 15px 0px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  font-family: 'Poppins';
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  color: white;
}

.member {
  border: 1px solid #0D2F92;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.groupback.row {
  box-shadow: 0px 4px 10px 0px #00000040;
  background: linear-gradient(180deg, rgba(0, 51, 153, 0.06) 0%, rgba(237, 28, 36, 0.06) 100%);
}

.form-member.row {
  padding: 25px 30px 40px;
}

a.docuview {
  width: 100%;
  margin-right: 10px;
  padding: 13px 28px;
  background: #003399;
  color: white;
  border: 1px solid transparent;
  font-size: 18px;
  border-radius: 5px;
  box-shadow: 0px 4px 4px 0px #00000040;
}

.search .fa {
  position: absolute;
  right: 30px;
  top: 11px;
  font-size: 20px;
  color: black;
  z-index: 9;
}

.search {
  position: relative;
  margin-right: 20px;
}

.search input {
  height: 45px;
  padding: 0px 20px;
  border-radius: 100px;
  border: solid 2px black;
}

.sidebaarrr {
  background: #FFFFFF;
  box-shadow: 0px 4px 4px 0px #00000040;
  border: none;
  border-radius: 0px;
  transition: width 0.3s ease;
}

ul.sidebaarr li {
  list-style-type: none;
  border-bottom: solid #dee2e6 1px;
  padding: 12px 5px;
}

ul.sidebaarr {
  padding-left: 0px;
  margin-bottom: 0px;
}

.sidebaarrr button.accordion-button {
  padding-left: 15px;
  color: black;
}



/* li.accordili a {
  text-decoration: none;
  padding: 0px 11px;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #000000;
} */

.accordili {
  list-style-type: none;
  border-bottom: solid #dee2e6 1px;
  padding: 12px 5px;
  transition: background-color 0.3s;
  /* Add transition for smoother hover */
}

.accordili:hover {
  background-color: #f2f2f2;
  /* Change background color on hover */
}

.accordili.active {
  background-color: #e5f4fe;
  /* Change background color for active item */
}

.accordili a {
  text-decoration: none;
  padding: 0px 11px;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #000000;
}

.sidebaarrr .accordion-body {
  padding: 0px;
}

/* Add animation for accordion open/close */
.accordili .accordion-header {
  cursor: pointer;
  transition: background-color 0.3s;
}

.accordili.active .accordion-header {
  background-color: red;
}

.sidebaarrr1 {
  background: #FFFFFF;
  box-shadow: 0px 4px 4px 0px #00000040;
  border: none;
  border-radius: 0px;
}

ul.sidebaarr1 li {
  list-style-type: none;
  border-bottom: solid #dee2e6 1px;
  padding: 12px 5px;
}

ul.sidebaarr1 {
  padding-left: 0px;
  margin-bottom: 0px;
}

.sidebaarrr .accordion-body {
  padding: 0px;
}

.highlight {
  border: 2px solid red;
}

.fa-eye {
  cursor: pointer;
  font-size: medium;
  color: white;
}

.fa-trash {
  cursor: pointer;
  font-size: medium;
  color: white;
}

ul.pagination.justify-content-end {
  margin-top: 15px;
  margin-right: 10px;
}

.image-container {
  width: 100%;
  /* Set desired width */
  height: 200px;
  /* Set desired height */
  overflow: hidden;
  /* Ensure content doesn't overflow */
  position: relative;
  /* For positioning */
}

.image-container img {
  width: 100%;
  height: 100%;
  /* object-fit: cover; */
  transition: transform 0.3s ease;
  /* Adjust according to your need */
}



.overlayimg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.overlay:hover {
  opacity: 2;
}

.download-icon {
  color: white;
  font-size: 30px;
  cursor: pointer;
}

.download-icon:hover {
  text-decoration: underline;
}

.righttttt {
  float: right;
}


button.buttonred1 {
  margin-right: 10px;
  padding: 6px 10px;
  background: #ed2a30;
  color: white;
  border: 1px solid transparent;
  font-size: 12px;
  border-radius: 5px;
  box-shadow: 0px 4px 4px 0px #00000040;
}

button.buttonblue1 {
  margin-right: 10px;
  padding: 6px 10px;
  background: #0D2F92;
  color: white;
  border: 1px solid transparent;
  font-size: 12px;
  border-radius: 5px;
  box-shadow: 0px 4px 4px 0px #00000040;
}

button.buttongreen1 {
  margin-right: 10px;
  padding: 6px 10px;
  background: #08900a;
  color: white;
  border: 1px solid transparent;
  font-size: 12px;
  border-radius: 5px;
  box-shadow: 0px 4px 4px 0px #00000040;
}

.procedureplan h4 {
  font-size: 20px;
  font-weight: 500;
  line-height: 18px;
  margin-bottom: 0px;
  text-align: center;
}

.procedureplan {
  box-shadow: 0px 4px 4px 0px #00000040;
  text-align: center;
  padding: 15px 0px;
  background: white;
}

.procedureplan1 {
  box-shadow: 0px 4px 4px 0px #00000040;
  padding: 15px 15px;
  background: white;
  margin-bottom: 15px;
}

.procedureplan button {
  margin-right: 10px;
  padding: 10px 25px;
  background: #0D2F92;
  color: white;
  border: 1px solid transparent;
  font-size: 12px;
  border-radius: 5px;
  box-shadow: 0px 4px 4px 0px #00000040;
}

.procedureplan1 p {
  line-height: 1.8;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0px;
}

.procedureplan2 {
  /* box-shadow: 0px 4px 4px 0px #00000040; */
  border: 1px solid #0D2F92;
  border-radius: 10px;
  padding: 25px 25px;
  background: white;
}

.col-lg-6.fofofo .form-control {
  border-radius: 8px;
  box-shadow: 0px 4px 4px 0px #00000040 !important;
  background: white;
  height: 55px;
  margin-bottom: 12px;
  font-size: 14px;
}

.col-lg-6.fofofo label {
  color: #000000;
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
  padding-left: 0px;
}

button.submitformm {
  margin-right: 10px;
  margin-top: 15px;
  float: right;
  padding: 10px 40px;
  background: #0D2F92;
  color: white;
  border: 1px solid transparent;
  font-size: 15px;
  border-radius: 5px;
  box-shadow: 0px 4px 4px 0px #00000040;
}

.amountsss {
  border: 1px solid #0D2F92;
  box-shadow: 0px 4px 4px 0px #00000040;
  background: white;
  text-align: center;
  margin-bottom: 40px;
  border-radius: 5px;
  padding-top: 15px;
  padding-bottom: 10px;
}

.amountsss h4 {
  font-family: 'Poppins';
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  color: #ED1C24;
  margin-bottom: 5px;
}

.amountsss p {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  color: #0D2F92;
  margin-bottom: 5px;
}

p.statusofclaim {
  font-family: 'Poppins';
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  color: black;
}

.selectoption.new.row select {
  border: #0D2F92 !important;
  color: white !important;
  border-radius: 0px;
  background: url(Image/whitearrow.png) #0D2F92 no-repeat calc(100% - 10px) !important;
}

button.searchclaimss {
  margin-right: 10px;
  padding: 10px 25px;
  background: #ed2a30;
  color: white;
  border: 1px solid transparent;
  font-size: 13px;
  border-radius: 5px;
}

.selectoption.new.row label {
  color: #000000;
  font-family: 'Poppins';
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 10px;
  padding-left: 0px;
}

.selectoption.new.row input {
  margin-right: 10px;
  padding: 10px 10px;
  background: #ffffff;
  color: black;
  border: 1px solid transparent;
  font-size: 14px;
  border-radius: 5px;
}

.custom-textarea {
  box-shadow: 0px 4px 4px 0px #00000040;
  width: 100%;
  padding: 10px;
  /* Example padding */
  border-radius: 5px;
  /* Example border radius */
}

.usefullinks h4 {
  box-shadow: 0px 4px 4px 0px #00000040;
  background: #0D2F92;
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  color: white;
  padding-top: 20px;
  padding-bottom: 20px;
}

.usefullinks {
  box-shadow: 0px 4px 4px 0px #00000040;
  background-color: white;
  text-align: center;
  padding-bottom: 20px;
  border-radius: 10px;
}

.wbeskkkjds a {
  font-family: 'Poppins';
  font-size: 13px;
  font-weight: 500;
  text-align: center;
  display: inline-block;
  border: 1px solid #0D2F92;
  color: #0D2F92;
  padding: 9px 18px;
  margin-top: 15px;
  margin-left: 5px;
  margin-right: 5px;
  text-decoration: none;
  border-radius: 100px;
}

p.common {
  font-family: 'Poppins';
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  color: black;
  margin-bottom: 30px;
}

p.paradatass {
  color: black;
  font-family: 'Poppins';
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 1px;
  text-align: justify;
  padding: 15px;
  margin-bottom: 0px;
}

p.paradatass {
  color: black;
  font-family: 'Poppins';
  font-size: 10px;
  line-height: 1.9;
  font-weight: 500;
  text-align: justify;
  padding: 15px;
  margin-bottom: 5px;
}

ul.ullliii li {
  font-family: 'Poppins';
  font-size: 15px;
  font-weight: 600;
}

.chartjs-tooltip {
  opacity: 1;
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  border-radius: 3px;
  transition: all .1s ease;
  pointer-events: none;
  line-height: 1.6;
  padding: 10px;
  text-align: center;
}

.row.policy_documents {
  border: solid #003399 1px;
}

.borderboderr.row {
  border-bottom: solid #003399 1px;
  margin: 0px;
  padding-top: 0px !important;
  padding: 0px;
}

.borderboderr.row h3 {
  font-family: Montserrat;
  font-size: 18px;
  text-align: center;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.3px;
}

.borderboderr.row button {
  width: 420px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.row.policy_documents1 {
  border: solid #003399 1px;
}

.borderleft {
  border-left: solid #003399 1px;
}

.custom-alert {
  text-align: center;
  font-size: 16px;
  padding: 10px;
  margin: 10px;
  display: flex;
  align-items: center;
  border: 1px solid #BC9300;
  font-weight: bold;
  color: black;
}

.alert-dismissible .btn-close {
  position: absolute;
  top: -7px;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.custom-alert p {
  margin-bottom: 5px;
  display: inline-block;
  justify-content: space-evenly;
  margin-right: 50px;
}



i.fa.fa-question-circle {
  /* position: absolute; */
  right: -20px;
  top: 15px;
  background-color: #FFFFFF;
  padding: 5px 6px;
  border-radius: 70px;
  border: 1px solid #050404;
  font-weight: bold;
  color: #039;
  width: auto;
  z-index: 99;
  font-size: 20px;
  cursor: pointer;
}

i.fa.fa-question-circle.sasasa {
  /* position: absolute; */
  right: 20px;
  top: 15px;
  background-color: #FFFFFF;
  padding: 5px 6px;
  border-radius: 70px;
  border: 1px solid #050404;
  font-weight: bold;
  color: #039;
  width: auto;
  z-index: 99;
  font-size: 20px;
  cursor: pointer;
}

i.fa.fa-question-circle.thanyou {
  position: absolute;
  right: 20px;
  top: 42px;
  background-color: #FFFFFF;
  padding: 5px 6px;
  border-radius: 70px;
  border: 1px solid #050404;
  font-weight: bold;
  color: #039;
  width: auto;
  z-index: 99;
  font-size: 20px;
  cursor: pointer;
}

/* SPECIAL INSTANT POLICY */

.pulse {
  animation: pulse-animation 2s infinite;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(220, 53, 69, 0.2);
  }

  100% {
    box-shadow: 0 0 0 20px rgba(220, 53, 69, 0);
  }
}

.ribbon {
  position: absolute;
  right: -5px;
  top: -5px;
  z-index: 1;
  overflow: hidden;
  width: 75px;
  height: 75px;
  text-align: right;
}

.ribbon span {
  font-size: 10px;
  font-weight: bold;
  color: #FFF;
  text-align: center;
  line-height: 20px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  width: 100px;
  display: block;
  background: #dc3545;
  position: absolute;
  top: 19px;
  right: -21px;
}