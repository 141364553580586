.typing {
    width: 5em;
    height: 2em;
    position: relative;
    padding: 10px 30px;
    margin-left: 5px;
    background: #e6e6e6;
    border-radius: 20px;
}

.typing__dot {
    float: right;
    width: 8px;
    height: 8px;
    margin: 0 4px;
    background: #8d8c91;
    border-radius: 50%;
    opacity: 0;
    animation: loadingFade 1s infinite;
}

.typing__dot:nth-child(1) {
    animation-delay: 0s;
}

.typing__dot:nth-child(2) {
    animation-delay: 0.2s;
}

.typing__dot:nth-child(3) {
    animation-delay: 0.4s;
}

@keyframes loadingFade {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0.8;
    }

    100% {
        opacity: 0;
    }
}