.thankyou-wrapper {
  width: 100%;
  height: auto;
  margin: auto;
  background: #ffffff;
  padding: 10px 0px 50px;
}

.thankyou-wrapper h1 {
  font: 100px Arial, Helvetica, sans-serif;
  text-align: center;
  color: #333333;
  padding: 0px 10px 10px;
}

a.gotodashboard {
  width: max-content;
  display: block;
  padding: 12px 40px;
  margin: auto;
  position: relative;
  transition: .6s;
  background: #003399;
  color: white;
  border: 1px solid transparent;
  font-size: 14px;
  text-decoration: none;
  overflow: hidden;
  z-index: 1;
  border-radius: 10px;
}

.thankyou-wrapper p {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
}
