.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffffad;
  z-index: 999;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}

.loader-container.visible {
  opacity: 1;
  visibility: visible;
}

.loader {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 10px solid transparent;
  border-top-color: #000000;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}