.login_form img {
    margin-left: 20px;
    margin-bottom: 25px;
    margin-top: 25px;
    width: 120px;
}

.login_form1 {
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    padding: 10px 10px 15px;
    margin-left: 20px;
    margin-right: 20px;
}

.login_form1 h2 {
    font-weight: 500;
    padding: 10px 0px;
    font-size: 23px;
    line-height: unset;
    text-align: center;
    letter-spacing: 1px;
    color: #000000;
    margin-bottom: 0px;
}

button.login_btnup {
    margin-top: 10px;
    padding: 15px 0px;
    background: #003399;
    width: 100%;
    border: solid #003399 1px;
    color: white;
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 30px;
    line-height: 25px;
}

span.login_forgot {
    text-align: center;
    font-weight: 500;
    font-size: 20px;
    display: block;
    color: #000000;
}

span.login_forgot a {
    color: #ED1C24;
    text-decoration: none;
}

p.emailaddress {
    text-align: center;
    font-weight: 500;
    font-size: 20px;
    color: #ed2a30;
}

span.or {
    text-align: center;
    font-weight: 500;
    font-size: 20px;
    display: block;
    color: #ed2a30;
}



.otp-box {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 1rem;
}

.otp-field {
    width: 50px;
    height: 50px;
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
    border: 1px solid #ced4da;
    border-radius: 4px;
    outline: none;
}

.otp1 {
    background: white;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    padding: 10px 10px;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20%;
    border-radius: 15px;
}

.otp1 h2 {
    font-weight: 500;
    padding: 10px 0px;
    font-size: 23px;
    line-height: unset;
    text-align: center;
    letter-spacing: 1px;
    color: #000000;
    margin-bottom: 0px;
}


button.resend_btnup {
    margin-top: 10px;
    padding: 15px 0px;
    background: #ED1C24;
    width: 100%;
    border: solid #ED1C24 1px;
    color: white;
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 30px;
    line-height: 25px;
}